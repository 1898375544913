body,
html {
  font-family: Arial, Helvetica, sans-serif;
  color: #151b31;
}
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap");
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
h1,
h2,
h3 {
  width: 100%;
  font-weight: 900;
  margin: 10px 0;
}
h1 > span,
h2 > span,
h3 > span {
  font-weight: 300;
}
.loginContainer{
    padding: 40px;
    background: #ffffff;
    width: 300px;
    margin: 20% auto;
    border: 1px solid #bdc1d4;
    border-radius: 5px;
    list-style-type: none;
    margin-bottom: 20px;
    text-align: center;
}
.logo {
  font-weight: 300;
  margin-left: 20px;
  color: #151b31;
  margin: 0 0 20px 0;
}
.logo > strong {
  font-weight: 900;
  color: #f0676a;
}
/* -------- Buttons -------- */

.btn {
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  border: solid 1px;
  text-decoration: none;
  line-height: normal;
  cursor: pointer;
  cursor: hand;
}
.btn.small {
  font-size: 0.8rem;
  padding: 6px 12px;
}
.btn:hover {
}
.btn.primary {
  color: #ffffff;
  background: #151b31;
  border-color: #151b31;
}
.btn.secondary {
  color: #151b31;
  background: transparent;
  border-color: #151b31;
}
.btn.google {
  background: url("/src/assets/btn_google_signin_light_normal_web.png");
  width: 191px;
  height: 46px;
  border: none;
}
.btn.google:active {
  background: url("/src/assets/btn_google_signin_light_pressed_web.png");
}
.btn.google:disabled {
  background: url("/src/assets/btn_google_signin_light_disabled_web.png");
}
.btn.google:focus {
  background: url("/src/assets/btn_google_signin_light_focus_web.png");
}

/* -------- Buttons -------- */

/* -------- Inputs -------- */

input {
  padding: 10px;
  border: 1px solid #bdc1d4;
  color: #151b31;
}
textarea {
  padding: 10px;
  border: 1px solid #bdc1d4;
}
label {
  font-size: 0.8rem;
  color: #7984ad;
}

/* -------- Inputs -------- */

/* -------- Generic -------- */

.marginTop-20 {
  margin-top: 20px !important;
}
.marginBottom-20 {
  margin-bottom: 20px !important;
}
.marginLeft-20 {
  margin-left: 20px !important;
}
.marginRight-20 {
  margin-right: 20px !important;
}
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
}
.loading > * {
  margin: 36px auto;
}
.versionInfo {
  margin: 10px 0 15px 0;
  padding: 10px;
  border: 1px solid #bdc1d4;
  border-radius: 5px;
  border-left: 3px solid #7984ad;
}
.versionInfo p {
  font-size: 0.8rem;
  color: #7984ad;
  margin: 0 0 5px 0;
}
/* -------- Generic -------- */
