.formContainer {
  padding: 20px;
  margin: 20px;
  border: 1px solid #bdc1d4;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.formContainer > input {
  width: 100%;
  margin: 10px 0;
}
