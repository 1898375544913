body, html {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  
  .container {
    width: 100%;
    height: 100%;
  }
  
  .leftpane {
      width: 25%;
      height: 100%;
      float: left;
      background-color: white;
      border-collapse: collapse;
  }
  
  .middlepane {
      width: 50%;
      height: 100%;
      float: left;
      background-color: white;
      border-collapse: collapse;
  }
  
  .rightpane {
    width: 25%;
    height: 100%;
    position: relative;
    float: right;
    background-color: white;
    border-collapse: collapse;
  }
  
  .toppane {
    width: 100%;
    height: 100px;
    border-collapse: collapse;
    background-color: white;
  }
  .hide-toolbar { display:none !important; }