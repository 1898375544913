.appCards {
  display: flex;
  margin: 0;
  padding: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.appCards li {
  padding: 20px;
  background: #ffffff;
  width: 32%;
  margin: 0;
  border: 1px solid #bdc1d4;
  border-radius: 5px;
  list-style-type: none;
  margin-bottom: 20px;
}
.appCards li h3 {
  margin: 0;
  font-weight: bold;
}
.appCards li p > a {display: block; margin-top: 5px;}
.appCards li > p {
  background: #e6e8f1;
  display: block;
  padding: 10px;
  border-radius: 5px;
  font-family: "Inconsolata", monospace;
  font-size: 0.8rem;
  border-left: 3px solid #f0676a;
}
.appCards li p a {
  color: #f0676a;
}
