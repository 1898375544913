.header {
  display: flex;
  height: 80px;
  background: #e6e8f1;
  margin: 0;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}
.header li {
  list-style-type: none;
}
.header li:last-child > * {
  margin: 0 10px;
}
.logo {
  font-weight: 300;
  margin-left: 20px;
  color: #151b31;
}
.logo > strong {
  font-weight: 900;
  color: #f0676a;
}
